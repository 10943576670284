/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from "@web3-react/core";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints,
} from "react-socks";
import { useDebounceFn } from "ahooks";
import { useCookies } from "react-cookie";

import {
  loginUser,
  onGetWalletAddress,
  verifyTokenUser,
} from "../../services/services";
import localStorageHelper, { KeyStorage } from "../../utils/localStorage";
import { truncateAddress, useQuery } from "../../utils/utils";
import ModalRegister from "../components/ModalRegister";
import {
  ChainInfos,
  connectors,
  supportedChainIds,
} from "../../utils/connectors";
import { get } from "lodash";
import { Spinner, Form } from "react-bootstrap";
import useOnclickOutside from "react-cool-onclickoutside";
import { useMenuContext } from "../../context/MenuContext";
import {
  LongRightArrowICon,
  CardICon,
  ArrowIcon,
  CloseIcon,
} from "../components/ICons";

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => {
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: true });
  let detailProd = useMatch({ path: "/detail", end: true });
  const isMatchInProdDetail = resolved.pathname === "/products" && detailProd;

  return (
    <Link
      {...props}
      className={match || isMatchInProdDetail ? "active" : "non-active"}
    />
  );
};

const Header = function ({
  isFullWidthMenu = true,
  handleKeySearch,
  initKeySearch = "",
  openWallet = false,
  setOpenWallet,
  setHideConnectButtonOnJumbotron = () => false,
  openRegisterModal = false,
  setOpenRegisterModal = () => false,
}) {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [filter, setFilter] = useState({
    search: "",
    select: undefined,
  });

  const navigate = useNavigate();
  const networkVersion = window.ethereum
    ? window.ethereum.networkVersion
    : undefined;

  const [cookies, setCookie, removeCookie] = useCookies([
    "wasOpenMetaMarkModal",
  ]);
  // Check Data Login
  const getSession = localStorageHelper.getObject(KeyStorage.SESSION);

  // Modal Wallet
  const { notSupport, onChangeSupport, isExtension, onChangeExtension } =
    useMenuContext();

  const closeMenu = () => {
    setOpenMenu(false);
  };

  const [showmenu, btn_icon] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  const closePopup = () => {
    setShowPopup(false);
  };

  const refPopup = useOnclickOutside(() => {
    closePopup();
  });

  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
      if (window.pageYOffset > sticky) {
        closeMenu();
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  const onSignUp = () => {
    setOpenRegisterModal(true);
  };

  // VERIFY SUCCESS
  const query = useQuery();
  const token = query.get("token");

  const isRunned = useRef(false);

  useEffect(() => {
    if (!isRunned.current) {
      if (token) {
        onVerifyToken(token);
      }
    }
    return () => {
      isRunned.current = true;
    };
  }, []);

  const onVerifyToken = async (token) => {
    try {
      const res = await verifyTokenUser(token);

      if (res && res?.status) {
        if (res.status === "SUCCESS") {
          localStorageHelper.setObject(KeyStorage.DATA, res.data);
          setOpenWallet(true);
        }
        navigate("/");
      }
    } catch (error) {
      navigate("/");
    }
  };

  // Connect Wallet
  const tokenData = JSON.parse(localStorageHelper.get(KeyStorage.DATA));

  const { account, activate, deactivate, active, chainId } = useWeb3React();

  const [loadingConnect, setLoadingConnect] = useState();

  useEffect(() => {
    const provider = window.localStorage.getItem("provider");
    if (provider) activate(connectors[provider]);
  }, []);

  const refreshState = () => {
    window.localStorage.setItem("provider", undefined);
  };

  const handleDisconnect = () => {
    deactivate();
    refreshState();
    closePopup();
    localStorageHelper.remove(KeyStorage.SESSION);
    localStorageHelper.remove(KeyStorage.DATA);
    setHideConnectButtonOnJumbotron(false);
  };

  const onConnect = async () => {
    setCookie("wasOpenMetaMarkModal", true, { expires: 0 });
    if (networkVersion === undefined) {
      onChangeExtension(true);
      setOpenWallet(false);
    } else {
      // Only Polygon Mainnet was supported
      if (!supportedChainIds.includes(Number(window.ethereum.networkVersion))) {
        await changeBlockchainNetwork();
        onChangeExtension(false);
        setOpenWallet(false);
        setCookie("wasOpenMetaMarkModal", false, { expires: 0 });
      } else {
        onChangeExtension(false);
        onChangeSupport(false);
        setLoadingConnect(true);
        await activate(connectors.injected);
        setProvider("injected");
        setOpenWallet(false);
        setLoadingConnect(false);
        setCookie("wasOpenMetaMarkModal", false, { expires: 0 });
      }
    }
  };

  const onConnectWallet = async () => {
    setOpenWallet(true);
  };

  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };

  useEffect(() => {
    if (account) {
      onCheckWalletAddress(account);
    }
  }, [account]);

  const onCheckWalletAddress = async (account) => {
    setLoadingConnect(true);
    try {
      const res = await onGetWalletAddress(account);
      if (res && res?.status) {
        if (res.status === "INVALID_WALLET_ADDRESS") {
          if (!isEmpty(tokenData)) {
            await onLoginData(tokenData, account);
            setLoadingConnect(false);
          } else {
            setOpenRegisterModal(true);
            setOpenWallet(false);
            handleDisconnect();
            setLoadingConnect(false);
          }
        } else if (res.status === "SUCCESS") {
          const tokenLogin = get(res, "token");
          await onLoginData(tokenLogin, account);
          setLoadingConnect(false);
        }
      }
    } catch (error) {
      setOpenRegisterModal(true);
      setOpenWallet(false);
      setLoadingConnect(false);
    }
  };

  const onLoginData = async (token, accountAddress) => {
    try {
      const res = await loginUser({
        token: token,
        walletAddress: accountAddress,
      });
      if (res && res.status === "SUCCESS") {
        localStorageHelper.setObject(KeyStorage.SESSION, res);
        setOpenRegisterModal(false);
        setOpenWallet(false);
        setHideConnectButtonOnJumbotron(true);
        localStorageHelper.remove(KeyStorage.USER_REGISTER);
      }
    } catch (error) {}
  };

  const changeBlockchainNetwork = async () => {
    const chainId = supportedChainIds[0];

    if (networkVersion.toString() !== chainId.toString()) {
      const chainInfo = ChainInfos[chainId];
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: chainInfo.chainIdHex }],
        });
        await activate(connectors.injected);
        setProvider("injected");
      } catch (err) {
        //Test Metamask Polygon Wallet film wagon sleep engage document return gather duty across trend cave pull
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: chainInfo.chainName,
                chainId: chainInfo.chainIdHex,
                nativeCurrency: chainInfo.nativeCurrency,
                rpcUrls: chainInfo.rpcUrls,
                blockExplorerUrls: [chainInfo.blockExplorer],
              },
            ],
          });
          await activate(connectors.injected);
          setProvider("injected");
        }
      }
    }
  };

  useEffect(() => {
    if (networkVersion) {
      if (supportedChainIds.includes(Number(networkVersion))) {
        onChangeSupport(false);
      } else {
        if (!openWallet && cookies.wasOpenMetaMarkModal === true)
          changeBlockchainNetwork();
      }
    }
  }, [networkVersion]);

  const { run } = useDebounceFn(
    (data, type) => {
      if (type === "search") {
        setFilter({
          ...filter,
          search: data || undefined,
        });
        handleKeySearch({
          ...filter,
          search: data || undefined,
        });
      }
    },
    { wait: 500 }
  );

  return (
    <header className={`navbar white`} id="myHeader">
      <div className="container">
        <div
          className={`${isFullWidthMenu ? "row w-100-nav" : "row w-50-nav"}`}
        >
          <div className="logo px-0">
            <div className="navbar-title navbar-item">
              <NavLink to="/">
                <img
                  src="/img/logo.png"
                  className="img-fluid d-block"
                  alt="logo"
                  width={180}
                />
              </NavLink>
            </div>
          </div>

          <BreakpointProvider>
            <Breakpoint l down>
              {showmenu && (
                <div className="menu">
                  <div className="navbar-item">
                    <NavLink to="/">Home</NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/products">Products</NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/faq">FAQs</NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/about">About</NavLink>
                  </div>
                  {active ? (
                    ""
                  ) : (
                    <div className="navbar-item">
                      <a className="cursor-pointer" onClick={onSignUp}>
                        Sign Up
                      </a>
                    </div>
                  )}
                </div>
              )}
            </Breakpoint>

            <Breakpoint xl>
              <div className="menu">
                <div className="navbar-item">
                  <NavLink to="/">
                    Home
                    <span className="lines"></span>
                  </NavLink>
                </div>
                <div className="navbar-item">
                  <NavLink to="/products">
                    Products
                    <span className="lines"></span>
                  </NavLink>
                </div>
                <div className="navbar-item">
                  <NavLink to="/faq">
                    FAQs
                    <span className="lines"></span>
                  </NavLink>
                </div>
                <div className="navbar-item">
                  <NavLink to="/about">
                    About
                    <span className="lines"></span>
                  </NavLink>
                </div>

                {active ? (
                  ""
                ) : (
                  <div className="navbar-item">
                    <a className="cursor-pointer" onClick={onSignUp}>
                      Sign Up
                    </a>
                  </div>
                )}
              </div>
            </Breakpoint>
          </BreakpointProvider>

          {!active ? (
            <div className="mainside">
              <div className="connect-wal">
                <NavLink className="cursor-pointer" onClick={onConnectWallet}>
                  Connect Wallet
                </NavLink>
              </div>
              {isFullWidthMenu && (
                <div
                  onClick={() => window.open("https://admin.koopon.xyz")}
                  className="for-brand"
                >
                  For Brands{" "}
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </div>
              )}
            </div>
          ) : (
            <div className="mainside" ref={refPopup}>
              <div
                className="dropdown-custom btn"
                onMouseEnter={() => setShowPopup(!showPopup)}
                onMouseLeave={closePopup}
              >
                <div id="de-click-menu-profile" className="de-menu-profile">
                  {loadingConnect ? (
                    <div className="text-center">
                      <Spinner animation="border" variant="primary" size="sm" />
                    </div>
                  ) : (
                    <div className="header-card">
                      <div className="content-card">
                        <div className="svg-card">
                          <CardICon />
                        </div>
                        <div className="address-card color-full">
                          {truncateAddress(account)}
                        </div>
                        <div className="arrow-card">
                          <ArrowIcon />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {showPopup && (
                  <div className="item-dropdown">
                    <div className="dropdown">
                      <div className="d-name">
                        <h4 className="color-full">
                          {get(getSession, "data.name")}
                        </h4>
                      </div>
                      <div className="d-line-header"></div>
                      <ul className="de-submenu-profile">
                        <li onClick={() => navigate("/my-items")}>
                          <span>
                            <i className="fa fa-user"></i> My items
                          </span>
                        </li>
                        <li onClick={handleDisconnect}>
                          <span>
                            <i className="fa fa-sign-out"></i> Disconnect
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
        {!isFullWidthMenu && (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="header-search-form row w-50-nav"
          >
            <i className="fa fa-search" aria-hidden="true"></i>
            <Form.Control
              onChange={(event) => run(event.target.value, "search")}
              placeholder="Search Koopon"
              defaultValue={initKeySearch}
            />
            {/* <select
              name="select-search"
              onChange={(e) => onChangeSearchSelect(e.target.value)}
              className="select-search"
            >
              <option value="COLLECTION">Product</option>
              <option value="CATEGORY">Category</option>
            </select> */}
          </Form>
        )}
      </div>

      <ModalRegister open={openRegisterModal} setOpen={setOpenRegisterModal} />

      {openWallet && (
        <div className="checkout">
          <div className="maincheckout">
            <button className="btn-close" onClick={() => setOpenWallet(false)}>
              <CloseIcon width={20} height={20} />
            </button>
            <div className="title-register color-full">Connect Your Wallet</div>
            <div className="content-register">
              <div>By connecting your wallet to Koopon, you’re agreeing</div>
              <div>
                to our{" "}
                <a href="/tos" style={{ textDecoration: "underline" }}>
                  Terms of Service
                </a>
                and{" "}
                <a href="/privacy" style={{ textDecoration: "underline" }}>
                  Privacy Policy
                </a>
                .
              </div>
            </div>
            <div className="meta-mask" onClick={onConnect}>
              <div className="d-flex">
                <img src="../../img/logo_meta.png" alt="logo" /> MetaMask
              </div>
              <div>
                <LongRightArrowICon />
              </div>
            </div>
          </div>
        </div>
      )}

      {notSupport ? (
        <div className="checkout">
          <div className="maincheckout">
            <button
              className="btn-close"
              onClick={() => {
                onChangeSupport(false);
                setCookie("wasOpenMetaMarkModal", false, { expires: 0 });
              }}
            >
              <CloseIcon width={20} height={20} />
            </button>
            <div className="title-register">Not support</div>
            <div className="content-register">
              <div>Network does not support</div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {isExtension ? (
        <div className="checkout">
          <div className="maincheckout">
            <button
              className="btn-close"
              onClick={() => onChangeExtension(false)}
            >
              <CloseIcon width={20} height={20} />
            </button>
            <div className="title-register">Install Extension</div>
            <div className="content-register">
              <div>Install metamask extension</div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </header>
  );
};
export default Header;
