import React from "react";
import { Route, Routes } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import ScrollToTopBtn from "./menu/ScrollToTop";
import Collections from "./pages/Collections";
import CollectionsUser from "./pages/CollectionsUser";
import Home from "./pages/Home";
import CollectionItemDetail from "./pages/CollectionItemDetail";
import FAQ from "./pages/FAQ";
import About from "./pages/About";
import AppProvider from "../contexts/AppContext";
import AndroidDownload from "./pages/AndroidDownload";
import IOSDownload from "./pages/IOSDownload";
import PageNotFound from "./pages/PageNotFound";
import BrandsUp from "./pages/BrandsUp";
import TermOfService from "./pages/TermOfService";
import Policy from "./pages/Policy";
import OrderButton from "./components/OrderButton";
import Checkout from "./pages/Checkout";

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

const App = () => (
  <AppProvider>
    <div className="wraper">
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route element={<Collections />} path="/products" />
        <Route element={<CollectionsUser />} path="/my-items" />
        <Route element={<CollectionItemDetail />} path="/detail" />
        <Route element={<FAQ />} path="/faq" />
        <Route element={<About />} path="/about" />
        <Route element={<TermOfService />} path="/tos" />
        <Route element={<Policy />} path="/privacy" />
        <Route element={<BrandsUp />} path="/brandssup" />
        <Route element={<AndroidDownload />} path="/download" />
        <Route element={<IOSDownload />} path="/suspended" />
        <Route element={<Checkout />} path="/checkout" />
        <Route element={<PageNotFound />} path="*" />
      </Routes>
      <ScrollToTopBtn />
      <OrderButton />
    </div>
  </AppProvider>
);
export default App;
