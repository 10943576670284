import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useWeb3React } from "@web3-react/core";
import { CloseIcon } from "./ICons";
import { useNavigate } from "react-router-dom";

interface Props {
  onClose?(): void;
}

interface ModalRef {
  showModal(): void;
}
const ModalCheckout: ForwardRefRenderFunction<ModalRef, Props> = (
  props,
  ref
) => {
  const { account } = useWeb3React();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    showModal: async () => {
      if (account) {
        navigate(`/checkout`);
      } else {
        setIsLogin(true);
      }
    },
  }));
  const handleCloseModal = () => {
    setIsLogin(false);
  };
  return (
    <>
      {isLogin ? (
        <div className="checkout" style={{ position: "relative" }}>
          <div className="maincheckout">
            <button className="btn-close" onClick={handleCloseModal}>
              <CloseIcon width={20} height={20} />
            </button>
            <div className="title-register color-full">Opp!!!</div>
            <div className="content-register">
              Please connect your wallet to continue
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default forwardRef(ModalCheckout);
