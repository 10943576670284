import { get } from "lodash";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { truncateString } from "../../utils/utils";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftCard2 = ({
  nft,
  className = "d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4",
  clockTop = true,
  height,
  collections,
  onClickBuyNow,
}) => {
  const navigate = useNavigate();

  const onClickHotCollections = (id) => {
    navigate(`/detail?id=${id}`);
  };

  const renderLeft = () => {
    let totalLeft = (nft?.supplyNft || 0) - (nft?.totalSold || 0);
    if (totalLeft > 0) {
      return totalLeft;
    }
    return 0;
  };

  const renderContent = () => {
    return (
      <div className="product-item">
        <div className="sale">SALE</div>
        <div className="nft__item_wrap" style={{ height: `${height}px` }}>
          <Outer>
            <span onClick={() => onClickHotCollections(nft._id)}>
              <img
                src={get(nft, "avatar.image")}
                className="lazy nft__item_preview cursor-pointer"
                alt=""
              />
            </span>
          </Outer>
        </div>
        <div className="product__item_info">
          <h4 className="mt-1" onClick={() => onClickHotCollections(nft._id)}>
            {truncateString(nft?.nftName, 33) || truncateString(nft?.name, 33)}
          </h4>
          <div className="d-flex justify-content-between">
            <div className="product__item_price">
              {nft && Number(nft.price) > 0 ? `$${nft.price}` : "Free"}
            </div>
          </div>
          <div className="product-star">
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
            <i className="fa fa-star" aria-hidden="true"></i>
          </div>
          <div className="buy-now" onClick={onClickBuyNow}>
            Buy Now - {nft && Number(nft.price) > 0 ? `$${nft.price}` : "Free"}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {collections ? (
        <div className={className}>{renderContent()}</div>
      ) : (
        renderContent()
      )}
    </>
  );
};

export default memo(NftCard2);
