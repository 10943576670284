import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { onGetCategory } from "../../services/services";
// import { isEmpty } from "lodash";
// import { convertCase } from "../../utils/utils";

import fashionImg from "../../assets/img/fashion.png";
import cosmeticsImg from "../../assets/img/cosmetics.png";
import petsImg from "../../assets/img/pets.png";
import luxuryImg from "../../assets/img/luxury.png";

const Category = () => {
  // const [dataCate, setDataCate] = useState([]);

  // useEffect(() => {
  //   getCategory();
  // }, []);

  // const getCategory = async () => {
  //   try {
  //     const res = await onGetCategory();
  //     setDataCate(res?.data);
  //   } catch (error) {
  //     setDataCate([]);
  //   }
  // };

  const categories = [
    {
      img: fashionImg,
      name: "FASHION",
    },
    {
      img: cosmeticsImg,
      name: "COSMETICS",
    },
    {
      img: petsImg,
      name: "PETS",
    },
    {
      img: luxuryImg,
      name: "LUXURY",
    },
  ];

  return (
    <div className="row justify-content-md-center mt-5 col-10 m-auto justify-content-center">
      <div className="mb-2 d-flex justify-content-between align-items-center">
        <h3
          className="color-full"
          style={{ fontSize: "28px", fontWeight: "900" }}
        >
          Check out categories
        </h3>
        <Link to="/products" className="view-all">
          View All
        </Link>
      </div>
      {/* {!isEmpty(dataCate)
        ? dataCate.slice(0, 4).map((d, index) => {
            return (
              <div
                className="col-md-3 col-sm-4 col-6 mb-3"
                key={`date_${index + 1}`}
              >
                <Link
                  className="icon-box style-2"
                  to={`/products?category=${d}`}
                >
                  <span>{convertCase(d)}</span>
                  <div className="icon-img">
                    <i className="fa fa-image"></i>
                  </div>
                </Link>
              </div>
            );
          })
        : ""} */}
      {categories.map((d, index) => {
        return (
          <div
            className="col-md-3 col-sm-4 col-6 mb-3 categories-body"
            key={`date_${index + 1}`}
          >
            <Link className="category-link" to={`/products?category=${d.name}`}>
              {/* <span>{convertCase(d)}</span>
                  <div className="icon-img">
                    <i className="fa fa-image"></i>
                  </div> */}
              <img src={d.img} alt={d.name} />
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Category;
