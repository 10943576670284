import React, { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import Header from "../menu/Header";
import Footer from "../components/Footer";
import { Col, Row, Form, Alert, Spinner } from "react-bootstrap";
import { StoreICon, TruckDeliveryICon } from "../components/ICons";
import { Countries } from "../components/constants/countries";
import { getListCanOrder, makeOrder } from "../../services/services";
import { truncateString } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
`;

const Checkout = () => {
  const navigator = useNavigate();
  const [openWallet, setOpenWallet] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [items, setItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [disabled, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [textError, setTextError] = useState("");
  const [receivePhone, setReceivePhone] = useState("");
  const [shippingMethod, setShippingMethod] = useState("SHIP");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [screen, setScreen] = useState("FORM_INFO");

  const onGetListCanOrder = async () => {
    setLoading(true);
    const res = await getListCanOrder();
    if (res.status === "SUCCESS" && res.data) {
      setItems(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    onGetListCanOrder();
  }, []);

  useEffect(() => {
    if (items.length) {
      const amount = items.reduce((pre, cur) => {
        return pre + cur.price * cur.transferToUserHistory.amount;
      }, 0);
      setTotalAmount(amount);
    }
  }, [items]);

  const handelSubmit = async (event) => {
    event.preventDefault();
    if (!items.length) {
      setTextError("Not NFT found!");
      return;
    }
    if (!receivePhone) {
      setTextError("Please input your receive phone number!");
      return;
    }
    if (!shippingMethod) {
      setTextError("Please select the shipping method!");
      return;
    }
    if (!country) {
      setTextError("Please select your country or region");
      return;
    }
    if (!address) {
      setTextError("Please input your receive address");
      return;
    }
    if (!firstName) {
      setTextError("Please input your first name");
      return;
    }
    if (!lastName) {
      setTextError("Please input your last name");
      return;
    }
    setDisable(true);

    const collectionSymbols = items.map((item) => item.symbol);
    const contractAddresses = items.map(
      (item) => item.web3CollectionAddressLowerCase
    );
    const amount = items.reduce((prev, cur) => {
      return prev + cur.transferToUserHistory.amount;
    }, 0);
    const body = {
      collectionSymbols,
      amount,
      receivePhone,
      shippingMethod,
      country,
      address,
      firstName,
      lastName,
      price: totalAmount,
      contractAddresses,
    };
    const res = await makeOrder(body);
    if (res.status === "SUCCESS" && res.data) {
      setTextError("");
      setScreen("ORDER_SUCCESS");
      setDisable(false);
    } else {
      setTextError(res.message);
      setDisable(false);
    }
  };

  const handleGoToHome = () => {
    navigator("/");
  };

  return (
    <div>
      <GlobalStyles />
      <Header
        openWallet={openWallet}
        setOpenWallet={setOpenWallet}
        openRegisterModal={openRegisterModal}
        setOpenRegisterModal={setOpenRegisterModal}
      />
      <section>
        <div
          className="container mt-5 d-flex flex-column justify-content-center align-items-start"
          style={{ minHeight: "200px" }}
        >
          <h1
            style={{ fontSize: "70px", fontWeight: "800" }}
            className="color-full mb-0"
          >
            Check out
          </h1>
          <Row className="mt-1 d-flex flex-row justify-content-between w-100">
            {textError ? (
              <Alert key={"danger"} variant={"danger"} className="mt-3">
                {textError}
              </Alert>
            ) : (
              ""
            )}
            {screen === "FORM_INFO" && (
              <Col md={6} className="p-4">
                <form method="POST" onSubmit={handelSubmit}>
                  <Row>
                    <Col md={12}>
                      <Form.Label className="fw-bold fs-6">Contact</Form.Label>
                      <input
                        type="number"
                        className="border py-2 px-3 border-radius-stripe form-control"
                        style={{ width: "100%" }}
                        placeholder="Mobile phone number"
                        name="receivePhone"
                        onChange={(e) => setReceivePhone(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <Form.Label className="fw-bold fs-6">
                        Delivery Method
                      </Form.Label>
                      <div className="custom-form-check form-1">
                        <Form.Check
                          type="radio"
                          label={
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <TruckDeliveryICon />
                              <span style={{ marginLeft: "3px" }}>Ship</span>
                            </div>
                          }
                          defaultChecked
                          name="shippingMethod"
                          value="SHIP"
                          onChange={() => setShippingMethod("SHIP")}
                          // disabled
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={12}>
                      <Form.Label className="fw-bold fs-6">
                        Shipping address
                      </Form.Label>
                      <select
                        name="country"
                        className="form-control"
                        defaultValue={0}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option disabled value={0}>
                          Country/Region
                        </option>
                        {Countries.map((item) => {
                          return (
                            <option key={item.code} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                    <Col md={6} className="mt-3">
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "100%" }}
                        placeholder="First name"
                        name="firstName"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Col>
                    <Col md={6} className="mt-3">
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "100%" }}
                        placeholder="Last name"
                        name="lastName"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Col>
                    <Col md={12} className="mt-3">
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "100%" }}
                        placeholder="Address"
                        name="address"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    {items.length > 0 && (
                      <Col md={3}>
                        <button
                          disabled={disabled}
                          className="form-control checkout-btn"
                          type="submit"
                        >
                          Submit
                        </button>
                      </Col>
                    )}
                  </Row>
                </form>
              </Col>
            )}
            {screen === "ORDER_SUCCESS" && (
              <Col md={6} className="p-4">
                <Alert key={"success"} variant={"success"}>
                  Order successfully. We sent the confirmation info to your
                  email address. Thanks!!!
                </Alert>
                <Row>
                  <Col md={3}>
                    <button
                      className="form-control checkout-btn"
                      type="button"
                      onClick={handleGoToHome}
                    >
                      Back to home
                    </button>
                  </Col>
                </Row>
              </Col>
            )}

            <Col md={6} className="checkout-info p-4">
              <Row>
                <Col md={12}>
                  <Form.Label className="fw-bold fs-6">
                    Products Summary
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {loading ? (
                    <div className="text-center">
                      <Spinner animation="border" variant="primary" size="sm" />
                    </div>
                  ) : items.length > 0 ? (
                    items.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex flex-row justify-content-between align-items-center mt-3"
                        >
                          <div className="d-flex flex-row justify-content-start align-items-center">
                            <span
                              style={{
                                position: "relative",
                                width: "80px",
                                height: "80px",
                                marginRight: "18px",
                              }}
                            >
                              <img
                                src={item.avatar?.image}
                                alt="item.name"
                                width={80}
                                height={80}
                                style={{
                                  border: "1px solid #d1d1d1",
                                  borderRadius: "5px",
                                }}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  width: "20px",
                                  height: "20px",
                                  border: "1px solid",
                                  padding: "2px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  top: "-8px",
                                  right: "-10px",
                                  fontSize: "10px",
                                  borderRadius: "50%",
                                  background: "#6902bd",
                                  color: "#fff",
                                }}
                              >
                                {item.transferToUserHistory?.amount || 0}
                              </span>
                            </span>
                            <span>
                              <a
                                href={`/detail?id=${item._id}`}
                                style={{ marginBottom: "0" }}
                              >
                                {truncateString(item.name, 72)}
                              </a>
                              <p style={{ fontSize: "11px", color: "gray" }}>
                                {truncateString(item.symbol, 52)}
                              </p>
                            </span>
                          </div>
                          {/* <div
                            style={{
                              fontWeight: "bold",
                              marginBottom: "33px",
                            }}
                          >
                            $
                            {(
                              item.price * item.transferToUserHistory.amount
                            ).toFixed(2)}
                          </div> */}
                        </div>
                      );
                    })
                  ) : (
                    <Alert key={"danger"} variant={"danger"} className="mt-1">
                      No NFT found
                    </Alert>
                  )}

                  {/* <hr style={{ margin: "15px 0 0 0" }} />
                  <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                    <span>Subtotal</span>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      ${totalAmount.toFixed(2)}
                    </span>
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center mt-1">
                    <span>Shipping</span>
                    <span>Free</span>
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center mt-1">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Total
                    </span>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      <small
                        style={{
                          fontWeight: "300",
                          color: "gray",
                          marginRight: "5px",
                        }}
                      >
                        USD
                      </small>
                      ${totalAmount.toFixed(2)}
                    </span>
                  </div> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Checkout;
